@keyframes fade-in-right {
  from {
    width: 10%;
  }

  to {
    width: 80%;
  }
}

.central-media-content hr {
  background-color: #d52b1e;
  border: none;
  height: 1vh;
  width: 80%;
  animation: fade-in-right 3s backwards;
}

.central-media-container {
  margin: 2vw 0;
  display: flex;
  height: 50vh;
  width: 100vw;
  justify-content: center;
}

.central-media-content {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  color: black;
  width: 50%;
  background-color: white;
}

.meet-slide {
  display: flex;
  flex-flow: row;
  width: 100%;
  justify-content: space-evenly;
  margin: 5%;
  align-items: center;
}

.central-media-content h3 {
  color: black;
  font-size: 2vw;
  margin: 0;
}

.meet-quote {
  width: 20vw;
  font-size: 2vh;
}

.central-media-content h4 {
  color: black;
  font-size: 1.5vw;
  margin: 0;
  width: 80%;
  text-align: center;
}

.secondary-image img {
  width: 20vw;
}
